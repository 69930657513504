// lista zamówień

import React, { useEffect, useMemo, useState } from 'react';
import { format, parse, endOfDay } from 'date-fns';
import { Trans, useTranslation } from 'react-i18next';
import Popover from '@mui/material/Popover';
import classnames from 'classnames';
import map from 'lodash/map';
import { X } from 'react-bootstrap-icons';

import { useRWD, useNotifications } from 'hooks';
import { reduxActions, useDispatch } from 'store';
import {
  useGetOrders,
  useGetOrderExportDocuments,
  usePostOrderEditDisable,
  usePostOrderEditEnable,
  useGetOrdersStatutes,
  useGetOrdersModes,
  useDeleteOrders,
  usePostOnlinePaymentInitialize,
  useGetOrderInformationVmp
} from 'api';
import { IOrderListItem, IOrdersRequest, IPaymentStatus, IDocument } from 'api/types';
import {
  Checkbox,
  SearchInput,
  Status,
  Select,
  Loader,
  DateRangePicker,
  Link as AppLink
} from 'components/controls';
import Table, { IColumn, ISorter } from 'components/controls/Table';
import { OrderSwitch } from 'components/containers';
import { DynamicComponent } from 'plugins/pages';
import { OrderActivate } from './components';

import { CheckIcon, Download, FinancesIcon, CheckboxIcon, CheckboxCheckedIcon } from 'assets/icons';

import styles from 'theme/pages/Orders/Orders.module.scss';

const Orders = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobile } = useRWD();
  const { showWarningMessage } = useNotifications();

  // domyślne parametry zapytania api o listę zamówień
  const defaultQueryParams = {
    page: 1,
    limit: 20
  };

  // ID wybranego zamówienia na potrzeby pobrania PDF
  const [chosenOrderId, setChosenOrderId] = useState<number | null>(null);

  // parametry zapytania do API o listę zamówień
  const [queryParams, setQueryParams] = useState<IOrdersRequest>(defaultQueryParams);

  const [checkedItemIds, setCheckedItemIds] = useState<number[]>([]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [sorter, setSorter] = useState<ISorter<IOrderListItem>>({
    by: 'create_date',
    direction: 'asc'
  });

  // czy jest widoczny modal łączenia zmówień
  const [isCombibingModalVisible, setCombiningModalVisible] = useState(false);

  // pobranie listy zamówień
  const { data: ordersData, refetch: refetchOrdersData } = useGetOrders(queryParams);

  const { data: orderInformation } = useGetOrderInformationVmp();

  const { mutate: orderDisable } = usePostOrderEditDisable({
    onSuccess: () => {
      refetchOrdersData();
    }
  });

  const { mutate: orderEnable } = usePostOrderEditEnable({
    onSuccess: () => {
      refetchOrdersData();
    }
  });

  // export zamówienia do pdf
  const { isFetching: isOrderExporting } = useGetOrderExportDocuments(
    chosenOrderId || 0,
    { export_type: 'pdf' },
    {
      enabled: !!chosenOrderId,
      onSuccess: (data) => {
        const a = document.createElement('a');
        a.download = data.file_name;
        a.href = data.url;
        a.click();
      },
      onSettled: () => setChosenOrderId(null)
    }
  );

  // pobieranie filtrów
  const { data: ordersModes } = useGetOrdersModes();

  // pobieranie filtrów
  const { data: ordersStatuses } = useGetOrdersStatutes();

  // usuwanie zamówień
  const { mutate: deleteOrders } = useDeleteOrders({
    onSuccess: () => {
      refetchOrdersData();
    }
  });

  //inicjalizacja metody płatności
  const { mutate: initializePayment } = usePostOnlinePaymentInitialize({
    onSuccess: ({ data }) => {
      if (data.redirect_type === 'REDIRECT_URL') {
        window.location.replace(data.redirect_payload);
      }
    }
  });

  // ustawienie breadcrumbs'ów po zamontowaniu komponentu
  useEffect(() => {
    dispatch(
      reduxActions.setBreadcrumbs([
        { name: t('Moje konto'), path: '/dashboard' },
        { name: t('Lista zamówień') }
      ])
    );
  }, []);

  const renderExportButton = (id: number, documents: IDocument[]) =>
    isOrderExporting && chosenOrderId === id ? (
      <div className={styles.exportLoader}>
        <Loader />
      </div>
    ) : (
      documents?.map((document, i) => (
        <button
          key={i}
          className={styles.exportButton}
          onClick={() => !chosenOrderId && setChosenOrderId(id)}>
          <span>{document.symbol}</span>
          <Download />
        </button>
      ))
    );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePaymentButtonClick = (
    type: null | string,
    id: number,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (type === 'INITIALIZE_PAYMENT') {
      initializePayment({
        context: 'ORDER_DETAILS',
        entity_type: 'ORDER',
        entity_id: id
      });
    }

    if (type === 'SHOW_BANK_DETAILS') {
      handleClick(event);
    }
  };

  const renderPayment = (item: IPaymentStatus, id: number) => {
    return (
      <>
        <div className={styles.paid} style={{ color: item.color }}>
          {item.icon && <img src={item.icon} />} {<span>{item.name}</span>}
        </div>
        {item.show_button_pay && (
          <div>
            <FinancesIcon />
            <button onClick={(e) => handlePaymentButtonClick(item.button_type, id, e)}>
              <Trans>zapłać</Trans>
            </button>
          </div>
        )}
      </>
    );
  };

  const renderDocument = (documents: IOrderListItem['documents']) => {
    return documents?.map((document, i) => (
      <div dangerouslySetInnerHTML={{ __html: document.symbol }} key={i} />
    ));
  };

  // kolumny tabelki z zamówieniami
  const columns: IColumn<IOrderListItem>[] = useMemo(
    () => [
      {
        title: <Trans>Data utworzenia</Trans>,
        dataIndex: 'create_date',
        align: 'center',
        sortBy: 'create_date',
        renderCell: (item) => {
          return (
            <AppLink className={styles.link} to={`/dashboard/orders/${item.id}`}>
              {item.create_date.slice(0, 10)}
            </AppLink>
          );
        }
      },
      {
        title: <Trans>Numer zamówienia</Trans>,
        dataIndex: 'id',
        align: 'center',
        renderCell: (item) => {
          const isChecked = checkedItemIds.includes(item.id);
          return (
            <div className={styles.id}>
              <Checkbox
                checked={isChecked}
                onClick={() =>
                  setCheckedItemIds(
                    isChecked
                      ? checkedItemIds.filter((checkbox) => checkbox !== item.id)
                      : [...checkedItemIds, item.id]
                  )
                }
                icon={<CheckboxIcon />}
                checkedIcon={<CheckboxCheckedIcon />}
              />
              <AppLink className={styles.link} to={`/dashboard/orders/${item.id}`}>
                {item.id}
              </AppLink>
              {item.is_editable ? (
                <OrderActivate orderId={item.id} isInEditMode={item.is_in_edit_mode} />
              ) : (
                <div className={styles.activateIconWrapper} />
              )}
            </div>
          );
        }
      },
      {
        title: <Trans>Status</Trans>,
        dataIndex: 'status',
        align: 'center',
        sortBy: 'status',
        renderCell: (item) => (
          <Status icon={item.status.icon} message={item.status.label} color={item.status.color} />
        )
      },
      {
        title: <Trans>Numer paczki</Trans>,
        dataIndex: 'package_number',
        align: 'center',
        renderCell: (item) => {
          return (
            <div className={styles.trackingNumber}>
              {item.delivery?.map((delivery, i: number) => (
                <div key={i}>
                  <a href={delivery.tracking_url} target="_blank" rel="noreferrer">
                    {delivery.tracking_number}
                  </a>
                </div>
              ))}
            </div>
          );
        }
      },
      {
        title: <Trans>Wartość zamówienia</Trans>,
        dataIndex: 'value_gross',
        align: 'center',
        renderCell: (item) =>
          `${item.value_gross_formatted.replace('.', ',') || '-'} ${item.currency}`
      },
      {
        title: <Trans>Płatność</Trans>,
        dataIndex: 'payment_status',
        align: 'center',
        renderCell: (item) => {
          if (item.status_payment_formatted.button_type === 'HIDDEN') {
            return null;
          }

          return (
            <div className={styles.payment}>
              {renderPayment(item.status_payment_formatted, item.id)}
            </div>
          );
        }
      },
      {
        title: <Trans>Wstrzymaj</Trans>,
        dataIndex: 'is_blocked',
        align: 'center',
        renderCell: (item) => (
          <OrderSwitch orderId={item.id} isBlocked={item.is_blocked} refetch={refetchOrdersData} />
        )
      },
      {
        title: <Trans>Dropshipping</Trans>,
        dataIndex: 'products_total_count',
        align: 'center',
        renderCell: (item) => item.dropshipping && <CheckIcon />
      },
      {
        title: <Trans>Dokument</Trans>,
        key: 'actions',
        align: 'center',
        renderCell: (item) =>
          item.has_document_files
            ? renderExportButton(item.id, item.documents)
            : renderDocument(item.documents)
      }
    ],
    [isOrderExporting, chosenOrderId, checkedItemIds, ordersData]
  );

  const renderOptionsFilter = () => {
    return (
      <div className={styles.filter}>
        <Select<any>
          options={[
            {
              label: t('Odblokuj zamówienia'),
              value: 1,
              item: 1,
              onClick: () => {
                if (!checkedItemIds.length) {
                  showWarningMessage(t('Nie wybrano zamówień do edycji'));
                  return;
                }

                orderEnable({
                  orders: map(checkedItemIds, (id) => ({
                    id: id
                  }))
                });
              }
            },
            {
              label: t('Wstrzymaj zamówienia'),
              value: 2,
              item: 2,
              onClick: () => {
                if (!checkedItemIds.length) {
                  showWarningMessage(t('Nie wybrano zamówień do edycji'));
                  return;
                }

                orderDisable({
                  orders: map(checkedItemIds, (id) => ({
                    id: id
                  }))
                });
              }
            },
            {
              label: t('Połącz zamówienia'),
              value: 3,
              item: 3,
              onClick: () => setCombiningModalVisible(true)
            },
            {
              label: t('Usuń zamówienia'),
              value: 4,
              item: 4,
              onClick: () => {
                deleteOrders({
                  orders: map(checkedItemIds, (id) => ({
                    id: id
                  }))
                });
              }
            }
          ]}
          onChange={() => null}
          placeholder={t('Opcje')}
        />
      </div>
    );
  };

  const beforedate = new Date();

  return (
    <div className={classnames(styles.wrapperComponent, 'StylePath-Pages-Orders')}>
      <div className={styles.filtersWrapper}>
        <div>
          <div className={styles.filter}>
            <SearchInput
              placeholder={t('Podaj symbol zamówienia')}
              value={queryParams.searchKeyword}
              onChange={(value) =>
                setQueryParams((prevState) => ({ ...prevState, searchKeyword: value }))
              }
            />
          </div>
          <div className={styles.filter}>
            <DateRangePicker
              dateRange={{
                from: queryParams.orderDateFrom
                  ? format(
                      parse(queryParams.orderDateFrom, 'yyyy-MM-dd HH:mm:ss', new Date()),
                      'yyyy-MM-dd HH:mm:ss'
                    )
                  : undefined,
                to: queryParams.orderDateTo
                  ? format(
                      parse(queryParams.orderDateTo, 'yyyy-MM-dd HH:mm:ss', new Date()),
                      'yyyy-MM-dd HH:mm:ss'
                    )
                  : undefined
              }}
              onChange={(range) => {
                setQueryParams((prevState) => ({
                  ...prevState,
                  orderDateFrom: range.from,
                  orderDateTo: range.to
                }));
              }}
              placeholder={`${format(
                new Date().setDate(beforedate.getDate() - 30),
                'yyyy-MM-dd'
              )} - ${format(endOfDay(beforedate), 'yyyy-MM-dd')}`}
            />
          </div>
          {isMobile && renderOptionsFilter()}
          <div className={styles.filter}>
            <Select<any>
              options={
                ordersModes?.items.map((mode) => ({
                  value: mode.value,
                  label: mode.label,
                  item: mode
                })) || []
              }
              value={queryParams.mode}
              onChange={(item) => {
                setQueryParams((prevState) => {
                  return {
                    ...prevState,
                    mode: item.value
                  };
                });
              }}
              placeholder={t('Wszystkie zamówienia')}
            />
          </div>
          <div className={styles.filter}>
            <Select<any>
              options={
                ordersStatuses?.items.map((mode) => ({
                  value: mode.value,
                  label: mode.label,
                  item: mode
                })) || []
              }
              value={queryParams.status}
              onChange={(item) => {
                setQueryParams((prevState) => {
                  return {
                    ...prevState,
                    status: item.value
                  };
                });
              }}
              placeholder={t('Wszystkie statusy')}
            />
          </div>
          {(queryParams.mode ||
            queryParams.status ||
            queryParams.orderDateFrom ||
            queryParams.orderDateTo) && (
            <button
              className={styles.clearFilters}
              onClick={() => {
                setQueryParams(defaultQueryParams);
              }}>
              <X />
              <Trans>Wyczyść filtry</Trans>
            </button>
          )}
        </div>
        {!isMobile && renderOptionsFilter()}
      </div>

      <Table<IOrderListItem>
        columns={columns}
        dataSource={ordersData?.items || []}
        rowKey="id"
        isOrders={true}
        sorter={sorter}
        pagination={{
          page: queryParams.page || 1,
          pagesCount: ordersData?.total_pages || 1,
          onChange: (page) => setQueryParams((prevState) => ({ ...prevState, page }))
        }}
        onSortChange={(sorter) => {
          setSorter(sorter);
          setQueryParams({
            ...queryParams,
            sort_method: `${sorter.by}_${sorter.direction}`
          });
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}>
        <div
          className={styles.popoverContent}
          dangerouslySetInnerHTML={{ __html: orderInformation?.information || '' }}
        />
      </Popover>
      {isCombibingModalVisible && (
        <DynamicComponent
          pageSymbol="order_combining"
          requestParams={{ orders_ids: checkedItemIds.toString().replaceAll(',', ';') }}
          componentParams={{
            isEditorInitialVisible: isCombibingModalVisible,
            fnCallback: (e: boolean) => setCombiningModalVisible(e),
            onErrorCallback: (e: boolean) => setCombiningModalVisible(e),
            refetchFn: () => refetchOrdersData()
          }}
        />
      )}
    </div>
  );
};

export default Orders;
