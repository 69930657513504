// główny layout - środkowa belka nagłówka

import React, { useState, useEffect, FC } from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import Drawer from '@mui/material/Drawer';

import { useDispatch, reduxActions, useSelector } from 'store';
import { Search, Languages, LoginButton, CartsButton } from 'components/containers';
import { Link } from 'components/controls';
import { EmailIcon, HamburgerIcon, PhoneIcon, UserMobileIcon } from 'assets/icons';
import { useRWD, useAppNavigate } from 'hooks';
import { OverLoginUsers } from 'components/controls';

import Categories from '../Categories';

import searchMobileIcon from 'assets/images/search-mobile.png';

import styles from 'theme/components/layouts/MainLayout/components/HeaderMain/HeaderMain.module.scss';

// typ danych wejściowych
interface IProps {
  simpleHeader?: boolean;
}

const HeaderMain: FC<IProps> = ({ simpleHeader }) => {
  const dispatch = useDispatch();
  const { isMobile } = useRWD();
  const location = useLocation();
  const navigate = useAppNavigate();

  const { profile, overloginUserId } = useSelector((state) => state.auth);

  const [open, setOpen] = useState(false);

  const [openSearch, setOpenSearch] = useState(false);

  // zamknięcie okienka po zmianie url'a
  useEffect(() => {
    setOpenSearch(false);
  }, [location.pathname]);

  useEffect(() => {
    if (open) {
      setOpenSearch(false);
    }
  }, [open]);

  return (
    <div
      className={classnames(
        styles.componentWrapper,
        { [styles.simpleHeader]: simpleHeader },
        'StylePath-Components-Layouts-MainLayout-Components-HeaderMain'
      )}>
      <div className={styles.container}>
        <div className={styles.main}>
          {!isMobile && (
            <div className={styles.customerInfo}>
              <span className={styles.user}>
                <EmailIcon />
                <a href="mailto:hurt@vmp.com.pl">hurt@vmp.com.pl</a>
              </span>
              <span className={styles.user}>
                <PhoneIcon />
                +48 505 44 94 95
              </span>
            </div>
          )}
          <Link
            to="/"
            onClick={() => (dispatch(reduxActions.setSearchKeyword('')), setOpen(false))}
            className={styles.logo}>
            <img src={`${process.env.REACT_APP_API_BASE_URL}/files/logo.png`} />
          </Link>
          {!isMobile && (
            <div className={styles.search}>
              <Search />
            </div>
          )}
          {isMobile && (
            <div className={styles.mobileActions}>
              <button onClick={() => (setOpenSearch(!openSearch), setOpen(false))}>
                <img src={searchMobileIcon} />
              </button>
              {profile?.role !== 'ROLE_OPEN_PROFILE' ? (
                <>
                  <div onClick={() => setOpen(false)}>
                    <CartsButton />
                  </div>

                  <button
                    onClick={() => {
                      setOpen(false);
                      navigate(location.pathname.includes('/dashboard') ? '/' : '/dashboard');
                    }}>
                    <UserMobileIcon />
                  </button>
                </>
              ) : (
                <LoginButton onClose={() => setOpenSearch(false)} />
              )}
              <button onClick={() => setOpen(!open)}>
                <HamburgerIcon />
              </button>
            </div>
          )}
        </div>
        {isMobile && (
          <>
            <Drawer
              SlideProps={{ style: { width: '76%', top: 79 } }}
              BackdropProps={{ style: { top: 79 } }}
              ModalProps={{ style: { top: 79 } }}
              anchor="right"
              open={open}
              onClose={() => setOpen(false)}>
              {open && (
                <div className={styles.drawerInner}>
                  <Languages />
                  <Categories
                    onCategoryClick={() => {
                      setOpen(false);
                    }}
                    onClose={() => setOpen(false)}
                  />
                  {profile?.role !== 'ROLE_OPEN_PROFILE' && <OverLoginUsers />}
                </div>
              )}
            </Drawer>
            {openSearch && (
              <div className={styles.search}>
                <Search />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default HeaderMain;
